@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Aptos';
  src: url('../public/aptos.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Aptos', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: disc; /* Type de puces */
  padding-left: 20px; /* Espace à gauche */
}

.slick-dots li button:before {
  font-size: 12px; /* Taille des points */
  color: white !important; /* Couleur des points */
}

.slick-dots li.slick-active button:before {
  color: white !important; /* Couleur du point actif */
}

.text-stroke {
  text-shadow: 3px 3px 3px black;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-left.visible {
  opacity: 1;
  transform: translateX(0);
}

.video-responsive {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 80%;
  width: 100%;
  position: absolute;
}
